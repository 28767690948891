
import { Component, Vue, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import SubscriptionCategoriesTable from '@/components/subscriptionCategories/SubscriptionCategoriesTable.vue';
import { namespace } from 'vuex-class';
import { SubscriptionCategory as SC } from '@/interfaces/models/SubscriptionCategories';

const foodcard = namespace('foodcard');
const venue = namespace('venue');

@Component({
  components: { VWrapper, SubscriptionCategoriesTable },
})
export default class SubscriptionCategories extends Vue {
  @venue.Action('fetch') public getVenues!: any;

  @foodcard.State((state) => state.categoryPagination.total) public total!: number[];

  @foodcard.Action('fetchSubscriptionCategories') protected getSubscriptionCategories!: any;
  @foodcard.Action('fetchSubscriptions') protected getSubscriptions!: any;

  @foodcard.Action('destroySubscriptionCategory') protected deleteSubscriptionCategory!: any;
  @foodcard.Action('setCategoryFilter') protected setFilter!: any;
  @foodcard.Action('setActiveCategory') protected setActive!: any;
  @foodcard.Action('updateCategory') protected updateCategory!: any;
  @foodcard.State('subscriptionCategories') protected items!: SC[];

  public async destroy(subscriptionCategory: SC) {
    await this.deleteSubscriptionCategory({ _id: subscriptionCategory._id });
  }

  public async mounted() {
    this.$startLoading('subscriptionCategories');
    this.$startLoading('subscriptions');
    await this.getSubscriptionCategories({ page: 1 });
    await this.getSubscriptions();
    await this.getVenues();
    this.$stopAllLoading();
  }
}
