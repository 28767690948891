
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import VTable from '@/components/shared/table/VTable.vue';
import headers from '@/components/subscriptionCategories/headers';
import { Permission } from '@/enums/Permission';
import adminHeaders from '@/components/subscriptionCategories/adminHeaders';
import { SubscriptionCategory } from '@/interfaces/models/SubscriptionCategories';

@Component({
  components: { VTable },
})
export default class SubscriptionCategoriesTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: SubscriptionCategory[];
  @Prop({ type: Number, required: true }) public total!: number;

  get headers() {
    if (this.$isAdmin()) {
      return adminHeaders;
    }

    return headers;
  }

  public edit(item: SubscriptionCategory) {
    this.$router.push({ name: 'subscriptionCategories.edit', params: { id: item._id } }).catch();
  }

  public canToggleVisbility(val: boolean) {
    if (val) {
      return this.$can(Permission.CATEGORY_VISIBLE);
    }
    return this.$can(Permission.CATEGORY_HIDE);
  }
}
